// @flow

import style from "./style.module.scss";

import React from "react";

import { Container, Col, Row } from "reactstrap";
import { Pagination, BlogQuickLinkCard } from "@2po-c21/components";
import Image from "gatsby-image";
import { CategoryLinks } from "@components";

import slugify from "slugify";
import { Link, navigate } from "gatsby";
import { gettext, t } from "ttag";

type Props = {
    blogs: *,
    locale: string,
    pagination: {
        pageCount: number,
        currentPage: number,
    },
    tag?: string,
};

const TAGS = [
    "Buying",
    "Selling",
    "Renting",
    "Invest",
    "C21 Daily",
    "Properties abroad",
];

const BlogOverview = ({
    blogs,
    locale,
    pagination: { currentPage, pageCount },
    tag,
}: Props) => {
    const onPageChange = (pageNr) => {
        navigate(
            `/${locale}/${t`slug.blog-overview`}${
                tag ? `/${slugify(tag).toLowerCase()}` : ""
            }/page${pageNr}`,
        );
    };

    return (
        <Container className={style.blogOverviewContainer}>
            <Row>
                <Col>
                    <CategoryLinks
                        links={TAGS.map((tag) => {
                            return {
                                displayValue: gettext(
                                    `blog.category.${slugify(tag)}`,
                                ),
                                value: `/${locale}/${t`slug.blog-overview`}/${slugify(
                                    tag,
                                ).toLowerCase()}/page1`,
                            };
                        })}
                    />
                </Col>
            </Row>
            <Row>
                {blogs.map(({ slug, pageContent }, index) => {
                    const LinkComponent = ({ children }: *) => (
                        <Link to={slug}>{children}</Link>
                    );

                    const content = pageContent[0];

                    return (
                        <Col
                            md={{ size: 12, offset: 1 }}
                            lg={{ size: 10, offset: 2 }}
                            xl={{ size: 6, offset: 0 }}
                            key={index}
                        >
                            <BlogQuickLinkCard
                                wideCard
                                title={content.title}
                                linkTitle={t`landing.blog.article`}
                                image={{
                                    // eslint-disable-next-line react/display-name
                                    imageSrc: () => (
                                        <Image
                                            fluid={content.heroImage.fluid}
                                            alt={
                                                content?.heroImage?.description
                                            }
                                        />
                                    ),
                                    description:
                                        content?.heroImage?.description,
                                }}
                                LinkComponent={LinkComponent}
                            />
                        </Col>
                    );
                })}
            </Row>
            <Row>
                <Pagination
                    nrOfPages={pageCount}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                />
            </Row>
        </Container>
    );
};

export default BlogOverview;
